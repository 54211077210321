import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { rem } from 'polished';
import Layout from '../components/layout';
import Keyvisual from '../components/keyvisual';
import Header from '../components/header';
import Container from '../components/container';
import Card from '../components/card';
import { keyvisualImageType, fluidImage, nodesOf } from '../helper/prop-types';
import SocialCard from '../components/socialCard';
import { mq } from '../helper/stylehelper';
import { getImageFromNode } from '../helper/utils';

const CardGrid = styled.div`
    /* IE FIX */
    display: block;
    ${mq.medium`
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    `};
    /* END IE FIX */

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, 1fr);
    grid-gap: ${rem(10)};
    grid-template-areas:
        'box1'
        'box2'
        'box3'
        'box4'
        'box5'
        'box6'
        'box7';
    margin: 0 ${rem(10)};

    ${mq.medium`
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(4, 1fr);
        grid-gap: ${rem(20)};
        grid-template-areas:
            'box1 box1 box3 box3'
            'box1 box1 box3 box3'
            'box2 box2 box5 box4'
            'box6 box6 box7 box7';
    `};

    ${mq.large`
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(4, 1fr);
        grid-gap: ${rem(20)};
        grid-template-areas:
            'box1 box1 box2 box2'
            'box1 box1 box3 box3'
            'box4 box5 box3 box3'
            'box6 box6 box7 box7';
    `};

    /* IE FIX */
    > * {
        margin: ${rem(10)};
        ${mq.medium`
            flex: 1 0 40%;
            flex-basis: calc(50% - 20px);
        `};

        @supports (display: grid) {
            margin: 0;
        }
    }
    /* END IE FIX */

    > *:nth-child(1) {
        flex-basis: calc(33% - 20px);
        grid-area: box1;
    }
    > *:nth-child(2) {
        flex-basis: calc(66% - 20px);
        grid-area: box2;
    }
    > *:nth-child(3) {
        grid-area: box3;
    }
    > *:nth-child(4) {
        grid-area: box4;
    }
    > *:nth-child(5) {
        flex-basis: calc(33% - 20px);
        grid-area: box5;
    }
    > *:nth-child(6) {
        flex-basis: calc(66% - 20px);
        grid-area: box6;
    }
    > *:nth-child(7) {
        grid-area: box7;
    }
`;

const IndexPage = ({ data: { keyvisualImages, cardImages } }) => (
    <Layout
        keyvisual={<Keyvisual images={keyvisualImages.edges.map(({ node }) => node)} />}
        woodBg
        title="Die Original Lechtaler – Bayerischer Wurstgenuss"
        description="Einzigartiger Geschmack und ein vielfältiges Produktsortiment: Die Original Lechtaler garantieren bayerischen Wurstgenuss zu jedem Anlass."
    >
        <Header isHomepage />
        <Container maxWidth="medium">
            <CardGrid>
                <Card
                    tag="Produkte"
                    text="Ob Bergwurst, Knabber-Stangerl oder Leberkäs – entdecke Die Original Lechtaler-Sortenvielfalt!"
                    to="/produkte/"
                    image={
                        getImageFromNode(cardImages, 'content/start/card-products.jpg').node
                            .childImageSharp.fluid
                    }
                    variant="dark"
                />
                <SocialCard network="facebook" />
                <Card
                    tag="Rezepte"
                    text="Einfach genießen: Von köstlichen Vesper-Kreationen, deftigen Leberkäs-Gerichten bis zu herzhaftem Wurstsalat."
                    to="/rezepte/"
                    image={
                        getImageFromNode(cardImages, 'content/start/card-recipes.jpg').node
                            .childImageSharp.fluid
                    }
                    variant="dark"
                />
                <SocialCard network="instagram" />
                <Card
                    tag="Wo kaufen?"
                    text="Finde heraus, wo es Die Original Lechtaler zu kaufen gibt."
                    to="/wo-kaufen/"
                    image={
                        getImageFromNode(cardImages, 'content/start/card-where-to-buy-square.jpg')
                            .node.childImageSharp.fluid
                    }
                    variant="dark"
                />
                <Card
                    tag="Über uns"
                    text="Alles Wissenswerte rund um Die Original Lechtaler: Von der Herkunft bis zur Qualitätssicherung."
                    to="/ueber-uns/"
                    image={
                        getImageFromNode(cardImages, 'content/start/card-aboutus.jpg').node
                            .childImageSharp.fluid
                    }
                    variant="dark"
                />
            </CardGrid>
        </Container>
    </Layout>
);

IndexPage.propTypes = {
    data: PropTypes.shape({
        keyvisualImages: nodesOf(keyvisualImageType),
        cardImages: nodesOf(
            PropTypes.shape({
                relativePath: PropTypes.string.isRequired,
                childImageSharp: fluidImage.childImageSharp,
            })
        ),
    }).isRequired,
};

export default IndexPage;

export const query = graphql`
    query {
        keyvisualImages: allFile(
            filter: { relativePath: { glob: "{content/start/keyvisual-*,logo-black-font.svg}" } }
        ) {
            edges {
                node {
                    relativePath
                    publicURL
                    childImageSharp {
                        original {
                            width
                            height
                        }
                        fluid(maxWidth: 1920, quality: 80) {
                            src
                            srcSet
                        }
                    }
                }
            }
        }

        cardImages: allFile(filter: { relativePath: { glob: "content/start/card-*.jpg" } }) {
            edges {
                node {
                    relativePath
                    childImageSharp {
                        fluid(maxWidth: 800, quality: 80) {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                    }
                }
            }
        }
    }
`;
