import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import qwest from 'qwest';
import styled from 'styled-components';
import { rem } from 'polished';
import Card from './card';
import { getImageFromNode } from '../helper/utils';

import logoInstagram from '../images/logo-instagram.svg';
import logoFacebook from '../images/logo-facebook.svg';

const logos = {
    instagram: logoInstagram,
    facebook: logoFacebook,
};

const SocialCardWrapper = styled(Card)`
    &::after {
        border-radius: 50%;
        box-shadow: 0 0 ${rem(8)} rgb(0, 0, 0, 0.6);
        content: ${props => `url(${logos[props.network]})`};
        display: block;
        height: ${rem(55)};
        position: absolute;
        right: ${rem(20)};
        top: ${rem(20)};
        width: ${rem(55)};
        z-index: 1;
    }
`;

export default class SocialCard extends Component {
    static accounts = {
        facebook: {
            id: 'DieOriginalLechtaler',
            url: 'https://www.facebook.com/DieOriginalLechtaler/',
            api: 'https://facebookfeed.gpapi.de/DieOriginalLechtaler?required=message,picture',
            transform: post => ({ text: post.message, image: post.full_picture }),
            fallback: {
                text: 'Besuche unseren Facebook-Kanal',
                image: fallbackImages =>
                    getImageFromNode(fallbackImages, 'content/start/card-facebook.png').node
                        .childImageSharp.fluid,
            },
        },
        instagram: {
            id: 'dieoriginallechtaler',
            url: 'https://www.instagram.com/dieoriginallechtaler/',
            api: 'https://instafeed.gpapi.de/dieoriginallechtaler',
            transform: post => ({ text: post.caption.text, image: post.images.low_resolution.url }),
            fallback: {
                text: 'Folge uns auf Instagram',
                image: fallbackImages =>
                    getImageFromNode(fallbackImages, 'content/start/card-instagram.png').node
                        .childImageSharp.fluid,
            },
        },
    };

    static propTypes = {
        network: PropTypes.oneOf(['facebook', 'instagram']).isRequired,
    };

    state = {
        isLoading: true,
        post: null,
        error: null,
    };

    /**
     * Lifecycle Hook bei Mount
     */
    componentDidMount() {
        const { network } = this.props;
        const account = SocialCard.accounts[network];

        qwest
            .get(account.api, null, { cache: true })
            .then((xhr, { data }) => {
                this.setState({
                    isLoading: false,
                    post: data[0] ? account.transform(data[0]) : account.fallback,
                });
            })
            .catch(error => {
                this.setState({ isLoading: false, error });
            });
    }

    /**
     * Rendert
     */
    render() {
        const { network } = this.props;
        const { isLoading, error, post } = this.state;
        const account = SocialCard.accounts[network];

        return (
            <StaticQuery
                query={graphql`
                    query {
                        fallbackImages: allFile(
                            filter: {
                                relativePath: {
                                    glob: "content/start/card-{facebook,instagram}.png"
                                }
                            }
                        ) {
                            edges {
                                node {
                                    relativePath
                                    childImageSharp {
                                        fluid(maxWidth: 400, quality: 80) {
                                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                        }
                                    }
                                }
                            }
                        }
                    }
                `}
                render={({ fallbackImages }) => {
                    if (error) {
                        return (
                            <SocialCardWrapper
                                text={account.fallback.text}
                                image={account.fallback.image(fallbackImages)}
                                to={account.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                network={network}
                                mode="hover"
                            />
                        );
                    }

                    if (!isLoading && typeof post.image === 'function') {
                        post.image = post.image(fallbackImages);
                    }

                    return (
                        <SocialCardWrapper
                            text={isLoading ? 'Lade Daten ...' : post.text}
                            image={isLoading ? account.fallback.image(fallbackImages) : post.image}
                            network={network}
                            to={account.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            mode="hover"
                        />
                    );
                }}
            />
        );
    }
}
