import React from 'react';
import styled, { keyframes } from 'styled-components';
import { position, rem } from 'polished';
import { ArrowDown } from 'styled-icons/feather/ArrowDown';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { fontSizes, colors, fontWeights } from '../helper/variables';
import { mq } from '../helper/stylehelper';

const animation = keyframes`
    0% {
        opacity: 0;
    }
    30% {
        opacity: 1;
    }
    60% {
        box-shadow: 0 0 0 60px rgba(255, 255, 255, 0.5);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
`;

const Link = styled(AnchorLink)`
    ${position('absolute', null, null, rem(8), '50%')};
    align-items: center;
    color: ${colors.blueDark};
    cursor: pointer;
    display: none;
    flex-direction: column;
    font-size: ${fontSizes.m};
    letter-spacing: 0.1em;
    text-align: center;
    text-decoration: none;
    transform: translateX(-50%);
    transition: opacity 0.3s;

    ${mq.medium`
        display: flex;
    `};
`;

const ArrowAnimation = styled.div`
    position: relative;
    z-index: 0;

    &::before {
        animation: ${animation} 3s infinite;
        position: absolute;
        z-index: -1;
        content: '';
        width: 100%;
        height: 100%;
        color: rgba(255, 255, 255, 0.1);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        opacity: 0;
    }
`;

const ArrowDownButton = styled(ArrowDown)`
    background: ${colors.blueDark};
    border-radius: 50%;
    font-weight: 300;
    font-size: 10px;
    padding: 5px;
`;

const Text = styled.span`
    font-size: ${fontSizes.xs};
    color: ${colors.darkBlue};
    font-weight: ${fontWeights.bold};
    margin-bottom: ${rem(5)};
    text-shadow: 1px 1px white;
`;

const ScrollDown = () => (
    <Link href="#content">
        <Text>Mehr erfahren</Text>
        <ArrowAnimation aria-hidden="true">
            <ArrowDownButton color="#FFFFFF" size="30" title="Mehr erfahren" />
        </ArrowAnimation>
    </Link>
);

export default ScrollDown;
