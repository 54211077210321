import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { rem } from 'polished';
import { animated, config, interpolate, Spring } from 'react-spring';
import {
    remIfNumber,
    vwMinMax,
    normalAspectRatioMq,
    landscapeMq,
    portraitMq,
    mq,
} from '../helper/stylehelper';
import { aspectRatioLandscape, breakpoints } from '../helper/variables';
import { keyvisualImageType } from '../helper/prop-types';
import ScrollDown from './scroll-down';
import { isInBrowser } from '../helper/utils';

const Container = styled.div`
    height: 100vh;
    overflow: hidden;
    position: relative;
    width: 100%;
`;

const relativeCssProp = (prop, value, mqAddon) => {
    if (value === null) {
        return '';
    }

    return Array.isArray(value)
        ? vwMinMax(prop, value[0], value[1], 320, 1920, false, mqAddon)
        : `${prop}: ${remIfNumber(value)};`;
};

// Erzeugt ein animierbares Bild oder ähnliches mit den speziellen Media-Queries für das Keyvisual
const keyvisualComponent = (
    {
        top = { normal: null, landscape: null, portrait: null },
        right = { normal: null, landscape: null, portrait: null },
        bottom = { normal: null, landscape: null, portrait: null },
        left = { normal: null, landscape: null, portrait: null },
        width = { normal: '100%', landscape: '100%', portrait: '100%' },
        height = { normal: 'auto', landscape: 'auto', portrait: 'auto' },
    },
    additionalStyles,
    tag = 'img'
) => {
    const KeyvisualComponent = styled(tag)`
        position: absolute;

        ${relativeCssProp('top', top.landscape, landscapeMq)};
        ${relativeCssProp('right', right.landscape, landscapeMq)};
        ${relativeCssProp('bottom', bottom.landscape, landscapeMq)};
        ${relativeCssProp('left', left.landscape, landscapeMq)};
        ${relativeCssProp('width', width.landscape, landscapeMq)};
        ${relativeCssProp('height', height.landscape, landscapeMq)};

        ${relativeCssProp('top', top.portrait, portraitMq)};
        ${relativeCssProp('right', right.portrait, portraitMq)};
        ${relativeCssProp('bottom', bottom.portrait, portraitMq)};
        ${relativeCssProp('left', left.portrait, portraitMq)};
        ${relativeCssProp('width', width.portrait, portraitMq)};
        ${relativeCssProp('height', height.portrait, portraitMq)};

        ${relativeCssProp('top', top.normal, normalAspectRatioMq)};
        ${relativeCssProp('right', right.normal, normalAspectRatioMq)};
        ${relativeCssProp('bottom', bottom.normal, normalAspectRatioMq)};
        ${relativeCssProp('left', left.normal, normalAspectRatioMq)};
        ${relativeCssProp('width', width.normal, normalAspectRatioMq)};
        ${relativeCssProp('height', height.normal, normalAspectRatioMq)};

        ${additionalStyles};
    `;

    return tag === 'img' ? animated(KeyvisualComponent) : KeyvisualComponent;
};

const ImgBg = keyvisualComponent(
    {
        left: { normal: 0, landscape: 0, portrait: 0 },
        top: { normal: 0, landscape: 0, portrait: 0 },
        height: { normal: '100%', landscape: '100%', portrait: '100%' },
    },
    css`
        object-fit: cover;
        object-position: left;
    `
);

const ImgLogo = keyvisualComponent(
    {
        left: { normal: '50%', landscape: '50%', portrait: '50%' },
        top: { normal: 30, landscape: 30, portrait: 30 },
        width: { normal: [120, 300], landscape: [200, 230], portrait: [150, 300] },
    },
    css`
        z-index: 1;
    `
);

const ImgHeadline = keyvisualComponent(
    {
        left: { normal: '60%', landscape: 'calc(5% + 230px)', portrait: '50%' },
        top: { normal: '45%', landscape: 'auto', portrait: '50%' },
        bottom: { normal: 'auto', landscape: 'calc(5% - 100px)', portrait: 'auto' },
        width: { normal: [300, 800], landscape: [350, 700], portrait: [300, 800] },
    },
    css`
        max-width: 90%;
        pointer-events: none;
    `
);

const ImgTeaser = keyvisualComponent(
    {
        right: { normal: [30, 50], landscape: [30, 50], portrait: [30, 50] },
        bottom: { normal: 0, landscape: 0, portrait: 0 },
        width: { normal: [180, 570], landscape: [180, 520], portrait: [180, 570] },
    },
    css`
        display: none;

        ${mq.large`
            display: block;
        `};
    `
);

const ImgFood = keyvisualComponent(
    {
        left: { normal: '50%', landscape: '50%', portrait: '50%' },
        top: { normal: '75%', landscape: '50%', portrait: '75%' },
        width: { normal: [200, 280], landscape: [150, 280], portrait: [150, 280] },
    },
    css`
        transform: translate(-50%, -50%);

        @media screen and (min-aspect-ratio: ${aspectRatioLandscape}) and (max-width: ${breakpoints.largeDown}) {
            left: auto;
            right: ${rem(-60)};
        }
    `
);

export default class Keyvisual extends Component {
    static propTypes = {
        images: PropTypes.arrayOf(keyvisualImageType).isRequired,
    };

    state = {
        imageLoaded: {},
    };

    /**
     * Lifecycle Hook bei Mount
     */
    componentDidMount() {
        const imageLoaded = {};
        const { images } = this.props;

        images.forEach(image => {
            imageLoaded[image.relativePath] = this.isAlreadyLoaded(
                image.publicURL,
                image.relativePath
            );
        });

        this.setState({ imageLoaded });
    }

    /**
     * Gibt ein Bild anhand des Dateinames zurück
     * @param {String} name Dateiname
     * @returns {Object}
     */
    getImage(name) {
        const { images } = this.props;

        return images.find(image => image.relativePath === name);
    }

    /**
     * Eventhandler wenn ein Bild geladen wurde
     * @param {String} src
     * @returns {Function}
     */
    loadHandler = src => () => {
        this.setState(prevState => ({
            imageLoaded: {
                ...prevState.imageLoaded,
                [src]: true,
            },
        }));
    };

    /**
     * Prüft ob das Bild bereits geladen ist
     * @param {String} publicURL Bildquelle als URL
     * @param {String} relativePath Bildquelle als Pfad
     */
    isAlreadyLoaded(publicURL, relativePath) {
        const img = new Image();
        img.addEventListener('load', this.loadHandler(relativePath));
        img.src = publicURL;
        return img.complete && img.naturalWidth;
    }

    /**
     * Rendert das Keyvisual
     */
    render() {
        const { imageLoaded } = this.state;
        const allImagesLoaded = Object.values(imageLoaded).every(loaded => loaded);

        return (
            <Container>
                <Spring
                    native
                    from={{
                        opacity: isInBrowser ? 0 : 1,
                        translate: isInBrowser ? -50 : 0,
                        scale: isInBrowser ? 0.8 : 1,
                    }}
                    to={{
                        opacity: allImagesLoaded ? 1 : 0,
                        translate: allImagesLoaded ? 0 : -50,
                        scale: allImagesLoaded ? 1 : 0.8,
                    }}
                >
                    {({ opacity, translate, scale }) => (
                        <ImgLogo
                            src={this.getImage('logo-black-font.svg').publicURL}
                            onLoad={this.loadHandler('logo-black-font.svg')}
                            alt="Die Original Lechtaler - Bayerischer Wurstgenuss"
                            style={{
                                opacity,
                                transform: interpolate(
                                    [translate, scale],
                                    (t, s) => `translate(-50%, ${t}%) scale(${s})`
                                ),
                            }}
                        />
                    )}
                </Spring>
                <Spring
                    native
                    from={{
                        opacity: isInBrowser ? 0 : 1,
                        transform: isInBrowser ? 'scale(1.2)' : 0,
                    }}
                    to={{
                        opacity: allImagesLoaded ? 1 : 0,
                        transform: allImagesLoaded ? 'scale(1)' : 'scale(1.2)',
                    }}
                >
                    {styles => (
                        <ImgBg
                            src={
                                this.getImage('content/start/keyvisual-bg.jpg').childImageSharp
                                    .fluid.src
                            }
                            srcSet={
                                this.getImage('content/start/keyvisual-bg.jpg').childImageSharp
                                    .fluid.srcSet
                            }
                            onLoad={this.loadHandler('content/start/keyvisual-bg.jpg')}
                            alt="Frau in Bett"
                            style={styles}
                        />
                    )}
                </Spring>
                <Link to="/produkte/" aria-label="Jetzt Produkte entdecken!">
                    <Spring
                        native
                        from={{
                            opacity: isInBrowser ? 0 : 1,
                            rotate: isInBrowser ? 90 : 0,
                        }}
                        to={{
                            opacity: allImagesLoaded ? 1 : 0,
                            rotate: allImagesLoaded ? 0 : 90,
                        }}
                    >
                        {({ opacity, rotate }) => (
                            <ImgTeaser
                                src={this.getImage('content/start/keyvisual-teaser.png').publicURL}
                                onLoad={this.loadHandler('content/start/keyvisual-teaser.png')}
                                alt="Jetzt Produkte entdecken!"
                                style={{
                                    opacity,
                                    transform: rotate.interpolate(r => `rotateX(${r}deg)`),
                                    transformOrigin: 'bottom center',
                                }}
                            />
                        )}
                    </Spring>
                </Link>
                <Spring
                    native
                    from={{
                        opacity: isInBrowser ? 0 : 1,
                        rotate: isInBrowser ? 90 : 0,
                    }}
                    to={{
                        opacity: allImagesLoaded ? 1 : 0,
                        rotate: allImagesLoaded ? 0 : 90,
                    }}
                >
                    {({ opacity, rotate }) => (
                        <ImgFood
                            src={this.getImage('content/start/keyvisual-food.png').publicURL}
                            onLoad={this.loadHandler('content/start/keyvisual-food.png')}
                            alt="Wurstscheibe"
                            style={{
                                opacity,
                                transform: rotate.interpolate(
                                    r => `translate(-50%, -50%) rotate(${r}deg)`
                                ),
                                transformOrigin: 'bottom center',
                            }}
                        />
                    )}
                </Spring>
                <Spring
                    native
                    config={config.wobbly}
                    from={{
                        opacity: isInBrowser ? 0 : 1,
                        rotate: isInBrowser ? -0.1 : 0,
                        scale: isInBrowser ? 0.7 : 1,
                    }}
                    to={{
                        opacity: allImagesLoaded ? 1 : 0,
                        rotate: allImagesLoaded ? 0 : -0.1,
                        scale: allImagesLoaded ? 1 : 0.7,
                    }}
                >
                    {({ opacity, rotate, scale }) => (
                        <ImgHeadline
                            src={this.getImage('content/start/keyvisual-headline.svg').publicURL}
                            onLoad={this.loadHandler('content/start/keyvisual-headline.svg')}
                            alt="Wenn du willst, dass sie zum Frühstück bleibt"
                            style={{
                                opacity,
                                transform: interpolate(
                                    [rotate, scale],
                                    (r, s) => `translate(-50%, -50%) rotate(${r}turn) scale(${s})`
                                ),
                            }}
                        />
                    )}
                </Spring>
                <ScrollDown />
            </Container>
        );
    }
}
